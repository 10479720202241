import React, { useEffect } from "react";
import {
  FloatingHelper,
  ToggleSwitch,
  FormField,
  EmptyState,
  CustomModalLayout,
  TextButton,
  Heading,
  Modal,
  MessageModalLayout,
  Text,
  Card,
  FeatureList,
  Box,
  Page,
  InputArea,
  Button,
  Cell,
  Layout,
  Input,
  WixDesignSystemProvider,
  Image,
  PageSection,
  Badge,
  MarketingLayout,
  AnnouncementModalLayout,
  Tooltip,
  MarketingPageLayoutContent,
  PulseAnimation,
  Thumbnail,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import * as Icons from "@wix/wix-ui-icons-common";
import "./App.css";
import { ReactTagManager } from "react-gtm-ts";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const [isHighlighted, setIsHighlighted] = React.useState(false);
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [isOpening, setIsOpening] = React.useState(false);
  const [shownSettingsModal, setShownSettingsModal] = React.useState(false);
  const [merchantId, setMerchantId] = React.useState("");
  const [hashKey, setHashKey] = React.useState("");
  const [hashIV, setHashIV] = React.useState("");
  const [isBusinessAppModalOpened, setIsBusinessAppModalOpened] =
    React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  });

  useEffect(() => {
    setIntercomIdentity();
    setInstance();
    getSettings();
  }, []);

  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");

  if (token) {
    window.location.href = `https://www.wix.com/installer/install?token=${token}&appId=52cd0660-060b-4139-b66b-d6fbeb562d2f&redirectUrl=https://certifiedcode.editorx.io/newebpay-pay-button/_functions/@certifiedcode/base-backend/auth`;
  }

  // if (!instance) {
  //   return null
  // }

  const BASE_URL = `https://certifiedcode.editorx.io/newebpay-pay-button/_functions`;

  function setIntercomIdentity() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/intercom", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.hash && data.email) {
          window.Intercom("boot", {
            email: data.email,
            user_hash: data.hash,
          });
        }
      })
      .catch(() => {});
  }

  function setInstance() {
    fetch(BASE_URL + "/@certifiedcode/base-backend/instance", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setInstanceData(data);
        setIsUpgraded(data?.instance?.isFree === false);
      })
      .catch(() => {});
  }

  function setSettings() {
    if (isUpgraded) {
      if (!open) {
        if (merchantId === "" || hashKey === "" || hashIV === "") {
          setShownSettingsModal(true);
          return;
        }
      }
      const account =
        !open === true
          ? {
              merchantId: merchantId,
              hashKey: hashKey,
              hashIV: hashIV,
            }
          : {};
      setIsOpening(true);
      fetch(BASE_URL + "/settings", {
        method: "POST",
        headers: {
          Authorization: instance || "",
        },
        body: JSON.stringify({
          isEnabled: !open,
          ...account,
        }),
      }).then(() => {
        setShownSettingsModal(false);
        setIsOpening(false);
        setOpen(!open);
      });
    } else {
      setIsUpgradeModalOpen(true);
    }
  }

  function getSettings() {
    setIsOpening(true);
    fetch(BASE_URL + "/settings", {
      method: "GET",
      headers: {
        Authorization: instance || "",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setIsOpening(false);
        setOpen(data.isEnabled);
      })
      .catch(() => {});
  }

  const isPremiumApp = true;

  const renderThumbnail = ({ title, subtitle, id }: any) => (
    <Thumbnail
      onClick={() => {
        if (window.location.host == "app.ecpay-pay-button.flows.tw") {
          window.Paddle.Checkout.open({
            product: id,
            email: (instanceData as any)?.site?.ownerInfo?.email,
            title: `藍新金流 Newebpay 進階集成 - ${title}`,
            allowQuantity: false,
            country: "TW",
            message: `通過「ECPay Pay Button」購買的「藍新金流 Newebpay 進階集成」。`,
          });
        } else {
          window.open("https://www.flows.tw/pricing?planId=" + id, "_blank");
        }
      }}
    >
      <Box padding="18px">
        <Box gap="12px" verticalAlign="middle">
          <Icons.Premium />
          <Box direction="vertical">
            <Text size="medium" weight="bold">
              {title}
            </Text>
            <Box>
              <Text size="small" secondary>
                {subtitle}
              </Text>
            </Box>
          </Box>
        </Box>
      </Box>
    </Thumbnail>
  );

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Page height="100vh">
        <Page.Header
          title={
            <Box gap={"SP2"} verticalAlign="middle">
              <Heading>藍新金流 Newebpay 付款按鈕</Heading>
            </Box>
          }
          actionsBar={
            <Box gap={"SP2"} direction="horizontal">
              {isPremiumApp ? (
                <Button
                  skin="premium"
                  prefixIcon={<Icons.PremiumFilled />}
                  onClick={() => setIsUpgradeModalOpen(true)}
                >
                  {isUpgraded ? "管理方案" : "接受線上付款"}
                </Button>
              ) : (
                <></>
              )}
            </Box>
          }
        />
        <Page.Content>
          <Modal
            isOpen={isBusinessAppModalOpened}
            onRequestClose={() => setIsBusinessAppModalOpened(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              onCloseButtonClick={() => {
                setIsBusinessAppModalOpened(false);
              }}
              title="進階集成"
              content={
                <Card>
                  <MarketingPageLayoutContent
                    title="開展 藍新金流 Newebpay 與 Wix 電子商務的集成"
                    content={
                      <Text>
                        <ul>
                          <li>通過 Wix 商店和預訂接受付款</li>
                          <li>無縫集成進行在線支付</li>
                          <li>使用 Wix 自動更新付款狀態</li>
                          <Box
                            alignContent="center"
                            gap="SP1"
                            direction="horizontal"
                          >
                            <li>在 Wix 中隨處付款</li>
                            <Badge size="tiny" skin="warningLight">
                              新
                            </Badge>
                          </Box>
                        </ul>
                      </Text>
                    }
                    actions={
                      <Layout>
                        <Cell>
                          <Layout cols={1} gap="12px">
                            <Text size="small" weight="bold">
                              選擇您的計劃
                            </Text>
                            {renderThumbnail({
                              title: "永久授權",
                              subtitle: "以 NT$20,000 單次購買，無需續訂。",
                              id: 843331,
                            })}
                            {renderThumbnail({
                              title: "年度訂閱",
                              subtitle:
                                "免費試用 14 天，然後以 NT$7999/年 自動續訂。隨時取消續訂。",
                              id: 842367,
                            })}
                            {renderThumbnail({
                              title: "月度訂閱",
                              subtitle:
                                "免費試用 14 天，然後以 NT$999/月 自動續訂。隨時取消續訂。",
                              id: 843756,
                            })}
                          </Layout>
                        </Cell>
                        <Cell>
                          <Box gap="SP2">
                            <Button
                              prefixIcon={<Icons.Help />}
                              skin="inverted"
                              as="a"
                              href="https://www.flows.tw/pricing"
                              target="_blank"
                            >
                              瞭解整合服務
                            </Button>
                            <Button
                              prefixIcon={<Icons.Phone />}
                              skin="light"
                              as="a"
                              href="https://calendly.com/certifiedcode/app-support-standard"
                              target="_blank"
                            >
                              與銷售人員交談
                            </Button>
                          </Box>
                        </Cell>
                        <Cell>
                          <Text size="tiny">
                            結帳時，可能會向你收取銷售稅。在扣款前，您可以預覽最終收費。繼續即表示您同意
                            <a
                              href="https://www.certifiedcode.us/legal/terms-of-services"
                              target="_blank"
                            >
                              Certified Code 認證代碼的服務條款
                            </a>
                          </Text>
                        </Cell>
                        <Cell></Cell>
                      </Layout>
                    }
                  />
                </Card>
              }
            />
          </Modal>
          <Modal
            isOpen={shownSettingsModal}
            onRequestClose={() => setShownSettingsModal(false)}
            shouldCloseOnOverlayClick
            screen="desktop"
          >
            <CustomModalLayout
              primaryButtonText="儲存"
              secondaryButtonText="取消"
              onCloseButtonClick={() => setShownSettingsModal(false)}
              secondaryButtonOnClick={() => setShownSettingsModal(false)}
              primaryButtonOnClick={() => setSettings()}
              removeContentPadding
              title="連線至收款帳號"
              content={
                <Page>
                  <Page.Content>
                    <Box marginTop={5} display="block">
                      <Layout>
                        <Cell span={12}>
                          <Box direction="vertical" gap="SP2">
                            <FormField
                              label="藍新商家代號"
                              infoContent="格式為七位數數字"
                            >
                              <Input
                                clearButton
                                value={merchantId}
                                onChange={(e) => setMerchantId(e.target.value)}
                              />
                            </FormField>
                            <FormField label="HashKey">
                              <Input
                                clearButton
                                value={hashKey}
                                onChange={(e) => setHashKey(e.target.value)}
                              />
                            </FormField>
                            <FormField label="HashIV">
                              <Input
                                clearButton
                                value={hashIV}
                                onChange={(e) => setHashIV(e.target.value)}
                              />
                            </FormField>
                          </Box>
                        </Cell>
                      </Layout>
                    </Box>
                  </Page.Content>
                </Page>
              }
            />
          </Modal>
          <Modal
            isOpen={isUpgradeModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setIsUpgradeModalOpen(false)}
          >
            <AnnouncementModalLayout
              theme="premium"
              title="接受線上付款"
              primaryButtonText="升級"
              linkText="瞭解更多"
              primaryButtonOnClick={() => {
                window.open(
                  `https://www.wix.com/apps/upgrade/${"52cd0660-060b-4139-b66b-d6fbeb562d2f"}?appInstanceId=${
                    (instanceData as any)["instance"]["instanceId"]
                  } `
                );
              }}
              linkOnClick={() => {
                window.open(
                  "https://support.certifiedcode.us/zh-TW/articles/7851533-開始使用綠界科技-ecpay-付款按鈕#h_38b837f3ef"
                );
              }}
              onCloseButtonClick={() => setIsUpgradeModalOpen(false)}
            >
              <Text>在一個價格，就可以通過付款按鈕接受線上付款！</Text>
            </AnnouncementModalLayout>
          </Modal>
          <Modal isOpen={isModalOpen} screen="desktop">
            <MessageModalLayout
              primaryButtonText="重新整理"
              primaryButtonOnClick={() => window.location.reload()}
              title="抱歉，發送一個錯誤！"
              content={<Text>請您重新整理頁面，或是聯絡我們的客服人員。</Text>}
            />
          </Modal>
          <Layout>
            <Cell span={12}>
              <Card>
                <Card.Header
                  title={
                    <Box gap={"SP2"}>
                      <Heading size="medium" as="h2">
                        接受線上付款
                      </Heading>
                      {isUpgraded ? (
                        <Badge size="tiny" skin={"success"}>
                          {"已就緒"}
                        </Badge>
                      ) : (
                        <FloatingHelper
                          target={
                            <Badge size="tiny" skin={"danger"}>
                              {"待升級"}
                            </Badge>
                          }
                          content={
                            <FloatingHelper.Content
                              title="不要忘記升級！"
                              body="在免費版，您的客戶只能使用測試模式結賬。"
                            />
                          }
                          placement="right"
                        />
                      )}
                    </Box>
                  }
                  suffix={
                    <ToggleSwitch
                      onChange={() => {
                        setSettings();
                      }}
                      size="medium"
                      checked={open && isUpgraded}
                      disabled={isOpening}
                    />
                  }
                />
                <Card.Subheader
                  title={
                    <Text size="small">
                      付款按鈕讓您可以在編輯器內放置收款按鈕，並支持用戶自填金額付款。
                      <b>此應用不整合至Wix電子商務。</b>
                    </Text>
                  }
                  suffix={
                    <Button
                      skin="light"
                      size="small"
                      onClick={() => {
                        setIsHighlighted(true);
                      }}
                    >
                      我需要整合至Wix商店
                    </Button>
                  }
                />
                <Card.Content>
                  {open && isUpgraded ? (
                    <>
                      <EmptyState
                        theme="section"
                        title="太好了！您正在接受線上收款！"
                        subtitle="要更改收款帳號，請先關閉「接受線上付款」，然後再開啟並重新填入。"
                      >
                        <TextButton
                          prefixIcon={<Icons.ExternalLink />}
                          as={"a"}
                          href={
                            (instanceData as any)["site"]
                              ? (instanceData as any)["site"]["url"]
                              : ""
                          }
                          target={"_blank"}
                        >
                          前往您的網站
                        </TextButton>
                      </EmptyState>{" "}
                    </>
                  ) : (
                    <></>
                  )}
                </Card.Content>
              </Card>
            </Cell>
            <Cell>
              <PageSection title="為您推薦" showDivider />
            </Cell>
            <Cell span={12}>
              <Card>
                <PulseAnimation
                  active={isHighlighted}
                  color="B10"
                  borderRadius="18px"
                  fluid={true}
                >
                  <MarketingLayout
                    title="接受線上付款"
                    description="借助 Certified Code 認證代碼業務解決方案，您可以通過 Wix 商店、預訂等接受客戶的在線付款。"
                    actions={
                      <Box direction="horizontal" gap="SP1">
                        <Button
                          size="small"
                          skin="premium"
                          onClick={() => {
                            setIsBusinessAppModalOpened(true);
                            setIsHighlighted(false);
                          }}
                        >
                          查看定價
                        </Button>
                        <Button
                          size="small"
                          skin="inverted"
                          onClick={() => {
                            setIsHighlighted(false);
                            window.Intercom(
                              "showNewMessages",
                              "我想在 Wix 商店和其他 Wix 應用程序上接受 ECPay 付款。"
                            );
                          }}
                        >
                          與銷售人員交談
                        </Button>
                      </Box>
                    }
                    size="tiny"
                    badge={<Badge size="small">90%商家正在使用</Badge>}
                    image={
                      <Box width="100%" align="right">
                        <Image
                          width="120px"
                          src="https://www.wix-pages.com/wix-design-system-employees/generic_upgrade.svg"
                          transparent
                        />
                      </Box>
                    }
                  />
                </PulseAnimation>
              </Card>
            </Cell>
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  size="tiny"
                  actions={
                    <Button
                      size="small"
                      priority="secondary"
                      suffixIcon={<Icons.WixForms />}
                      onClick={() => {
                        window.Intercom("startSurvey", 44499573);
                      }}
                    >
                      Share Feedback
                    </Button>
                  }
                  title="Looking for something else?"
                  description="Share your feedback with us"
                ></MarketingLayout>
              </Card>
            </Cell>
            {/* <Cell span={6}>
              <Card>
                <MarketingLayout
                  title="接受線上付款"
                  description="透過 Flows. 整合OS，您可以使用本地支付服務商讓客戶使用台灣付款方式。"
                  actions={<Button size="small" onClick={() => { window.open("https://www.flows.tw/payments/newebpay") }}>瞭解更多</Button>}
                  size="tiny"
                  badge={<Badge size="small">第三方整合</Badge>}
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-pages.com/wix-design-system-employees/generic_upgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell> */}
            <Cell span={12}>
              <Card>
                <MarketingLayout
                  title="與支持團隊聯繫"
                  description="當您遇到問題時，我們的支持團隊會幫助您解決並提供建議。"
                  actions={
                    <Button
                      size="small"
                      onClick={() => {
                        window.open(
                          "https://calendly.com/certifiedcode/app-support-standard"
                        );
                      }}
                    >
                      預約通話
                    </Button>
                  }
                  size="tiny"
                  hiddenBadge
                  alignItems="stretch"
                  image={
                    <Box width="100%" align="right">
                      <Image
                        width="120px"
                        src="https://www.wix-pages.com/wix-design-system-employees/PromotionalBookingsUpgrade.svg"
                        transparent
                      />
                    </Box>
                  }
                />
              </Card>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
